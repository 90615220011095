import React, {
  useCallback,
  useRef,
  useState,
  ChangeEvent,
  FocusEvent,
  HTMLProps,
  ReactNode,
  RefObject,
} from 'react';

import { DropdownArrow } from '@travel/icons/ui';

import Alert from '../Alert';
import Options, { Option } from './components/Options';

import isNotEmptyArray from '../../utils/arrays';
import cx from '../../utils/classnames';

import styles from './selectBox.module.scss';

export type Props = {
  /** Id for accessibility purposes */
  id?: string;
  /** Style name to control appearance of this component from parent component*/
  className?: string;

  /** Style name to control appearance of selectBox from parent component*/
  selectBoxClassName?: string;

  /** Style name to control appearance of selectedOption from parent component*/
  selectedOptionClassName?: string;

  /** Dropdown icon */
  dropdownIcon?: ReactNode;

  /** Option(s) for the select box */
  options?: Array<Option>;

  /** Label text above select box */
  label?: ReactNode;

  /** Error message to be displayed under select box */
  errorMessage?: ReactNode;

  /** If true, text field will have error style (red border) */
  hasError?: boolean;

  /** Call back to be called after value gets changed. Event object will be passed through argument */
  onChange?: (event: ChangeEvent<HTMLSelectElement>) => void;

  /** Call back to be called after component lost focus. Event object will be passed through argument */
  onBlur?: (event: FocusEvent<HTMLSelectElement>) => void;

  /** Value of the component. */
  value?: string | number;

  /** Default value of the component if nothing is selected. */
  defaultValue?: string;

  /** html name of input element */
  name?: string;

  /** place holder text of input element */
  placeholder?: ReactNode;

  /** unit of selection */
  unit?: string;

  /** Should select box be required */
  isRequired?: boolean;

  /** Should select box be disabled */
  isDisabled?: boolean;

  /** highlight input field till value changed. */
  shouldHighlightAtFirst?: boolean;

  /** Flag to define whether the select box will be applied with border or not  */
  hasNoBorder?: boolean;

  selectRef?: RefObject<HTMLSelectElement>;

  /** hidden label for a11y */
  isLabelHidden?: boolean;
} & Omit<HTMLProps<HTMLSelectElement>, 'label' | 'onChange'>;

const DEFAULT_OPTION = { text: '', value: '' };

function SelectBox(props: Props) {
  const {
    id,
    className,
    selectBoxClassName,
    selectedOptionClassName,
    options,
    label,
    hasError,
    errorMessage,
    onChange,
    onBlur,
    value,
    defaultValue,
    name,
    placeholder,
    isRequired,
    isDisabled,
    shouldHighlightAtFirst,
    unit,
    dropdownIcon = <DropdownArrow />,
    selectRef,
    hasNoBorder,
    isLabelHidden,
    ...rest
  } = props;

  const { current: isControlled } = useRef(value !== undefined);
  const initialLoad = useRef(true);
  const shouldHighlight = initialLoad.current && shouldHighlightAtFirst;

  let initOptionValue: Option = DEFAULT_OPTION;
  let newDefaultValue: any;

  const getSelectOptionByValue = useCallback(
    (value: string | number): Option => {
      //check fo empty string is for placeholder, check placeholder option
      if (value === undefined || value === '') return initOptionValue;
      if (isNotEmptyArray(options))
        return options.find(option => option.value === value) || DEFAULT_OPTION;

      return DEFAULT_OPTION;
    },
    [initOptionValue, options],
  );

  if (isControlled) {
    newDefaultValue = undefined;
    initOptionValue = {
      text: (value && getSelectOptionByValue(value).text) || value || placeholder || '',
      value: value || '',
    };
  } else {
    newDefaultValue =
      defaultValue || (!placeholder && isNotEmptyArray(options) ? options[0].value : undefined);
    //Get Init Option Value first from defaultValue , if not in defaultValue than placeholder,
    //if not placeholder than first option, if no option than default empty option
    if (defaultValue) initOptionValue = getSelectOptionByValue(defaultValue);
    else if (placeholder) initOptionValue = { text: placeholder, value: '' };
    else if (isNotEmptyArray(options)) initOptionValue = options[0];
    else initOptionValue = DEFAULT_OPTION;
  }

  const [selectedOption, setSelectedOption] = useState(initOptionValue);
  const [isTouch, setIsTouch] = useState(false);

  const handleSelectionChange = (event: ChangeEvent<HTMLSelectElement>) => {
    initialLoad.current = false;

    if (isControlled) {
      onChange && onChange(event);
    } else {
      setSelectedOption(getSelectOptionByValue(event.target.value));
      onChange && onChange(event);
    }
  };

  const handleBlur = (event: FocusEvent<HTMLSelectElement>) => {
    setIsTouch(true);
    onBlur && onBlur(event);
  };

  const hasErrorState = hasError || (isTouch && isRequired && !value) || !!errorMessage;

  return (
    <div className={cx(styles.container, className)}>
      {label && (
        <label
          className={isLabelHidden ? cx(styles.label, styles.hidden) : styles.label}
          htmlFor={id}
        >
          {label}
        </label>
      )}
      <div className={styles.selectBoxWrapper}>
        <div
          className={cx(styles.selectBox, selectBoxClassName, shouldHighlight && styles.highlight)}
        >
          <select
            id={id}
            data-testid="selectBox"
            className={cx(
              styles.hiddenSelect,
              hasErrorState && styles.error,
              hasNoBorder && styles.noBorder,
            )}
            name={name}
            onBlur={handleBlur}
            onChange={handleSelectionChange}
            value={value}
            defaultValue={newDefaultValue}
            disabled={isDisabled}
            required={isRequired}
            ref={selectRef}
            {...rest}
          >
            {placeholder && (
              <option key="placeholder" value="">
                {placeholder}
              </option>
            )}
            {isNotEmptyArray(options) && <Options options={options} selectedValue={value} />}
          </select>
          <span className={styles.defaultIcon}>{dropdownIcon}</span>
          {/* When select contains long text, ellipsis need to be displayed.
          But ellipsis works for <div> and not work for <select>
          So we made the opacity as 0 for select, and make <div class="viewport"> to display current option text.
          Please reffer:
          https://www.frontendmemo.xyz/entry/2016/09/14/003138 */}
          <div
            data-testid="selectBox-selected-wrapper"
            className={cx(
              styles.viewport,
              hasErrorState && styles.error,
              hasNoBorder && styles.noBorder,
              selectedOptionClassName,
            )}
          >
            {isControlled
              ? initOptionValue.text
              : selectedOption && (
                  <div className={styles.selectedOption}>
                    {selectedOption.icon && selectedOption.icon}{' '}
                    <p data-testid="selectBox-selected-text">{selectedOption.text}</p>
                  </div>
                )}
          </div>
        </div>
        {unit && (
          <label data-testid="unit" className={styles.unit}>
            {unit}
          </label>
        )}
      </div>
      {hasErrorState && errorMessage && (
        <Alert type="error" title={errorMessage} isValidation={true} isClosable={false} />
      )}
    </div>
  );
}

export default SelectBox;
