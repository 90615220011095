import React, { ReactNode } from 'react';

import { Attention } from '@travel/icons/ui';
import { cx } from '@travel/utils';

import styles from './errorMessage.module.scss';

type Color =
  | 'appleRed'
  | 'default'
  | 'cilantro'
  | 'sunflower'
  | 'blueRaspberry'
  | 'cyberGrape'
  | 'white'
  | 'lightGrey'
  | 'pastelGray'
  | 'sonicSilver'
  | 'lincolnGreen';

export type Props = {
  /** Id of error wrapper. Used for accessibility */
  id?: string;
  className?: string;
  /** String of main title */
  title?: ReactNode;
  /** String of error message */
  message: ReactNode;
  /** Type of icon color */
  iconColor?: Color;
  /** Number of icon width and height */
  iconSize?: number;
  /** Flag to define whether background color will be transparent or not */
  hasTransparentBackground?: boolean;
  /** To overwrite icon className */
  iconClassName?: string;
};

function ErrorMessage({
  title,
  message,
  className,
  id,
  iconColor = 'appleRed',
  iconSize = 14,
  hasTransparentBackground,
  iconClassName,
  ...rest
}: Props) {
  return (
    <div
      data-testid="errorMessage-wrapper"
      data-alert-id="error-alert"
      className={cx(styles.container, hasTransparentBackground && styles.bgTransparent, className)}
      {...rest}
    >
      <Attention
        color={iconColor}
        size={iconSize}
        className={cx(styles.icon, iconClassName)}
        data-testid="errorMessage-error-icon"
        aria-hidden={true}
      />
      <p id={id} className={styles.textContainer}>
        {title && (
          <span className={styles.title} data-testid="errorMessage-title-text">
            {title}
          </span>
        )}
        <span>{message}</span>
      </p>
    </div>
  );
}

export default ErrorMessage;
