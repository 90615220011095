import React from 'react';
import cx from '../../utils/classnames';
import styles from './couponRow.module.scss';

type Props = {
  className?: string;
  title: string | React.ReactNode;
  children: string | React.ReactNode;
};

function CouponRow(props: Props) {
  const { className, title, children, ...rest } = props;

  return (
    <div className={cx(className, styles.row)} {...rest}>
      <span>{title}</span>
      <span>{children}</span>
    </div>
  );
}

CouponRow.defaultProps = {
  className: '',
};

export default CouponRow;
