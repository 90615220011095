import React, { Fragment, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';

import { L10nDate, L10nNumber } from '@travel/i18n';
import { GetTranslation } from '@travel/translation';

import Money from 'components/Money';

import { getMember } from 'store/member/selectors';

import { Translate } from 'core/translate';
import { DaysOfTheWeerStr } from 'SalesPromotion-Types';
import { getPaymentTypeLabel } from 'utils/enums';

const ERROR_LABEL_IDS: { [key: number]: string } = {
  400: 'Coupon_Details.User_Login.Status', // might have update on status code in future
  404: 'Traveler_Input_Error.Coupon.Promotion_Code.Invalid_Code',
  409: 'Traveler_Input_Error.Coupon.Promotion_Code.Promotion_Already_Used', // obtained
  410: 'Traveler_Input_Error.Coupon.Promotion_Code.Promotion_Expired', // expired
};

export const useCouponInputErrorLabel = () => {
  const [errorCode, setErrorCode] = useState<number | null>(null);
  const resetCouponError = useCallback(() => setErrorCode(null), []);
  const { isMember } = useSelector(getMember);

  return {
    set: setErrorCode,
    reset: resetCouponError,
    labelId: errorCode
      ? // currently 400 can be returned when
        // 1. non-member tries to acquire member-only coupon
        // 2. user is not included in the user segment of the coupon
        (((isMember && errorCode !== 400) || !isMember) && ERROR_LABEL_IDS[errorCode]) ||
        ERROR_LABEL_IDS[404]
      : '',
    isError: Boolean(errorCode),
  };
};

export const DAY_OF_WEEK_LABEL = {
  SUNDAY: 'Sun',
  MONDAY: 'Mon',
  TUESDAY: 'Tue',
  WEDNESDAY: 'Wed',
  THURSDAY: 'Thu',
  FRIDAY: 'Fri',
  SATURDAY: 'Sat',
};

export const useCouponDetailLabels = (
  getTranslation: GetTranslation,
  currency?: string,
  localCurrency?: string,
) => {
  return {
    applicableAreaTitle: {
      title: <Translate id="Coupon_Details.Area_Applicable.Title" />,
      infoResolver: (value?: string) =>
        value ? value : <Translate id="Coupon_Details.Area_Applicable.Displayed_Information" />,
    },
    applicableMarkets: {
      title: <Translate id="Coupon_Details.Applicable_User_Region.Title" />,
      infoResolver: (markets?: string[]) => (
        <Translate
          id="Coupon_Details.Applicable_User_Region.Displayed_Information"
          data={{
            applicable_user_region: markets
              ?.map(code =>
                getTranslation({
                  id: `Traveler_Country_List.${code}`,
                }),
              )
              .join(', '),
          }}
        />
      ),
    },
    maxUsesPerMember: {
      title: <Translate id="Coupon_Details.Coupon_Usage.Title" />,
      infoResolver: (maxUsesPerMember?: number, maxUse?: number, maxUsesPerProvider?: number) => {
        return (
          <>
            {maxUse && (
              <div>
                <Translate
                  id="Coupon_Details.Coupon_Usage.Displayed_Information"
                  count={maxUse}
                  data={{ max_uses_per_member: maxUse }}
                />
              </div>
            )}
            {maxUsesPerMember && (
              <div>
                <Translate
                  id="Coupon_Details.Coupon_Usage.Max_Use_User"
                  data={{ max_use_per_user: maxUsesPerMember }}
                />
              </div>
            )}
            {maxUsesPerProvider && (
              <div>
                <Translate
                  id="Coupon_Details.Coupon_Usage.Max_Use_Provider"
                  data={{ max_use_per_provider: maxUsesPerProvider }}
                />
              </div>
            )}
          </>
        );
      },
    },
    travelPeriod: {
      title: <Translate id="Coupon_Details.Travel_Period.Title" />,
      infoResolver: (from: string, to: string) => {
        let status: string;

        if (from && to) {
          status = 'travel_period_to_from_set';
        } else {
          status = from ? 'travel_period_from_set' : 'travel_period_to_set';
        }

        return (
          <Translate
            id="Coupon_Details.Travel_Period.Displayed_Information"
            condition={() => `status == ${status}`}
            data={{
              travel_period_from: from && <L10nDate value={from} format="L" />,
              travel_period_to: to && <L10nDate value={to} format="L" />,
            }}
          />
        );
      },
    },
    excludedDates: {
      title: <Translate id="Coupon_Details.Excluded_Dates.Title" />,
      infoResolver: (from: string, to: string, text?: string) => {
        if (text || from === to) {
          return <L10nDate value={text || from} format="L" />;
        } else {
          return (
            <Translate
              id="Coupon_Details.Excluded_Dates.Displayed_Information"
              exist={true}
              data={{
                from_date: <L10nDate value={from} format="L" />,
                to_date: <L10nDate value={to} format="L" />,
              }}
            />
          );
        }
      },
    },
    target_weekdays: {
      title: <Translate id="Coupon_Details.Target_Weekdays" />,
      infoResolver: (days?: Array<DaysOfTheWeerStr>) => {
        if (!days || days.length < 1) {
          return null;
        }
        const lastIndex = days.length - 1;

        return days.map((day, index) => (
          <Fragment key={`dayOfTheWeek_${day}_${index}`}>
            <Translate id={`Traveler_Common.Calendar.Day.${DAY_OF_WEEK_LABEL[day]}`} />
            {index !== lastIndex && ', '}
          </Fragment>
        ));
      },
    },
    noOfGuests: {
      title: <Translate id="Coupon_Details.Total_Guests.Title" />,
      infoResolver: (from: number, to: number) => {
        let status: string;

        if (from && to) {
          status = 'guests_from_guests_to_set';
        } else {
          status = from ? 'guests_from_set' : 'guests_to_set';
        }

        return (
          <Translate
            condition={() => `status == ${status}`}
            id="Coupon_Details.Total_Guests.Displayed_Information"
            data={{
              number_of_guests_from: from,
              number_of_guests_to: to,
            }}
          />
        );
      },
    },
    noOfAdults: {
      title: <Translate id="Coupon_Details.Adults.Title" />,
      infoResolver: (from: number, to: number) => {
        let status: string;

        if (from && to) {
          status = 'adults_from_adults_to_set';
        } else {
          status = from ? 'adults_from_set' : 'adults_to_set';
        }

        return (
          <Translate
            condition={() => `status == ${status}`}
            id="Coupon_Details.Adults.Displayed_Information"
            data={{
              number_of_adults_from: from,
              number_of_adults_to: to,
            }}
          />
        );
      },
    },
    noOfChildren: {
      title: <Translate id="Coupon_Details.Children.Title" />,
      infoResolver: (from: number, to: number) => {
        let status: string;

        if (from && to) {
          status = 'children_from_children_to_set';
        } else {
          status = from ? 'children_from_set' : 'children_to_set';
        }

        return (
          <Translate
            condition={() => `status == ${status}`}
            id="Coupon_Details.Children.Displayed_Information"
            data={{
              number_of_children_from: from,
              number_of_children_to: to,
            }}
          />
        );
      },
    },
    stayLength: {
      title: <Translate id="Coupon_Details.Stay_Length.Title" />,
      infoResolver: (from: number, to: number) => {
        let status: string;

        if (from && to) {
          status = 'stay_length_from_stay_length_to_set';
        } else {
          status = from ? 'stay_length_from_set' : 'stay_length_to_set';
        }

        return (
          <Translate
            condition={() => `status == ${status}`}
            id="Coupon_Details.Stay_Length.Displayed_Information"
            count={to}
            data={{
              stay_length_from: (
                <span>
                  {from}&nbsp;
                  <Translate
                    id="Coupon_Details.Stay_Length.Displayed_Information.Nights"
                    count={from}
                  />
                </span>
              ),
              stay_length_to: (
                <span>
                  {to}&nbsp;
                  <Translate
                    id="Coupon_Details.Stay_Length.Displayed_Information.Nights"
                    count={to}
                  />
                </span>
              ),
            }}
          />
        );
      },
    },
    reservationAmount: {
      title: <Translate id="Coupon_Details.Reservation_Amount.Title" />,
      infoResolver: (from: string, to: string) => {
        let status: string;

        if (from && to) {
          status = 'res_amount_from_res_amount_to_set';
        } else {
          status = from ? 'res_amount_from_set' : 'res_amount_to_set';
        }

        return (
          <Translate
            condition={() => `status == ${status}`}
            id="Coupon_Details.Reservation_Amount.Displayed_Information"
            data={{
              reservation_amount_from: <Money value={from} currency={currency} />,
              reservation_amount_to: <Money value={to} currency={currency} />,
            }}
          />
        );
      },
    },
    maxDiscount: {
      title: <Translate id="Coupon_Details.Maximum_Discount.Title" />,
      infoResolver: (value: number, isShowLocalValue?: boolean, localValue?: number) => (
        <>
          <Translate
            id="Coupon_Details.Maximum_Discount.Displayed_Information"
            exist={true}
            data={{
              upper_value_of_discount: <Money value={value} currency={currency} />,
            }}
          />
          {isShowLocalValue && (
            <>
              <br />
              <Translate
                id="Coupon_Details.Maximum_Discount.Local_Amount.Displayed_Information"
                condition={() => `status == different_currency`}
                data={{
                  local_amount: <Money value={localValue} currency={localCurrency} />,
                }}
              />
            </>
          )}
        </>
      ),
    },
    maxPoint: {
      title: (
        <Translate id="Coupon_Details.Maximum_Points.Title" condition={() => `exist == true`} />
      ),
      infoResolver: (value: number, isShowLocalValue?: boolean, localValue?: number) => (
        <>
          <Translate
            id="Coupon_Details.Maximum_Discount.Displayed_Information"
            data={{
              upper_value_of_discount: (
                <Translate
                  id="Coupon_Details.Coupon.Value_And_Suffix"
                  condition={() => `status == point_promotion`}
                  data={{
                    point_back: <L10nNumber value={value} />,
                  }}
                />
              ),
            }}
          />
          {isShowLocalValue && (
            <>
              <br />
              <Translate
                id="Coupon_List.Coupon.Local_Amount"
                condition={() => `status == different_currency`}
                data={{
                  local_amount: (
                    <Translate
                      id="Coupon_Details.Coupon.Value_And_Suffix"
                      condition={() => `status == point_promotion`}
                      data={{
                        point_back: <L10nNumber value={localValue || 0} />,
                      }}
                    />
                  ),
                }}
              />
            </>
          )}
        </>
      ),
    },
    payment: {
      title: <Translate id="Coupon_Details.Payment.Title" />,
      infoResolver: (methods?: string[]) => {
        return methods
          ?.filter(method => Boolean(method))
          .map(item => getPaymentTypeLabel(item))
          .join(', ');
      },
    },
    userCondition: {
      title: <Translate id="Coupon_Details.User_Condition.Title" />,
      infoResolver: (
        isRakutenMemberRequired?: boolean,
        standardNewUserRequired?: boolean,
        providerNewUserRequired?: boolean,
      ) => (
        <>
          {isRakutenMemberRequired && (
            <div>
              <Translate
                id="Coupon_Details.User_Condition.Displayed_Information"
                exist={isRakutenMemberRequired}
              />
            </div>
          )}
          {standardNewUserRequired && (
            <div>
              <Translate
                id="Coupon_Details.User_Condition.First_Time_Use"
                exist={standardNewUserRequired}
              />
            </div>
          )}
          {providerNewUserRequired && (
            <div>
              <Translate id="Coupon_Details.User_Condition.First_Time_Use_Provider" />
            </div>
          )}
        </>
      ),
    },
    budget: {
      title: <Translate id="Coupon_Details.Budget.Title" />,
      infoResolver: (rakutenTotalBudget?: number, providerTotalBudget?: number) => (
        <Translate
          id="Coupon_Details.Budget_Disclaimer"
          condition={() => `status == only_rakuten_total_budget_set`}
        />
      ),
    },
  };
};
