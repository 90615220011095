import React from 'react';

import L10nDateTime from '../Date';

type Props = {
  promotionStart?: string;
  promotionEnd?: string;
};

const CouponDate = (props: Props) => {
  if (!props?.promotionStart && !props?.promotionEnd) {
    return null;
  }

  return (
    <>
      {props?.promotionStart && (
        <L10nDateTime
          value={props?.promotionStart}
          dateFormat="L"
          timeFormat="LT"
          isDisplayAsLocalTime={true}
        />
      )}
      {props?.promotionEnd && (
        <>
          {props?.promotionStart && ' - '}
          <L10nDateTime
            value={props?.promotionEnd}
            dateFormat="L"
            timeFormat="LT"
            isDisplayAsLocalTime={true}
          />
        </>
      )}
    </>
  );
};

export default CouponDate;
