import React from 'react';

import { INCENTIVE_CALCULATION_METHOD, INCENTIVE_METHOD } from '@travel/traveler-core/constants';
import { AcquiredCoupon } from '@travel/traveler-core/types/acquiredCoupons';
import { CouponDetails } from '@travel/traveler-core/types/salesPromotion';
import { CouponRow } from '@travel/ui';

import isNotEmptyArray from '../../utils/arrays';

import styles from './couponDetail.module.scss';

type Props = {
  className?: string;
  couponTextClassName?: string;
  customHeaderTitle?: string | React.ReactNode;
  couponDetail: CouponDetails | AcquiredCoupon;
  currency: string;

  // translation
  labels: { [key: string]: { title?: React.ReactNode; infoResolver?: any } };
};

type TextRowProps = {
  title: React.ReactNode;
  className?: string;
  info?: React.ReactNode;
  isHidden?: boolean;
};

const TextRow = (props: TextRowProps) => {
  if (props.isHidden) return null;

  return (
    <CouponRow title={props.title} className={props.className}>
      <>{props.info}</>
    </CouponRow>
  );
};

function CouponDetail(props: Props) {
  const {
    className,
    couponTextClassName,
    customHeaderTitle,
    labels,
    couponDetail,
    currency,
    ...rest
  } = props;

  const conditions = couponDetail?.conditions;
  const incentiveAmount = couponDetail.incentiveAmount;
  const applicableMarkets = couponDetail?.applicableMarkets;

  const isShowLocalAmount = couponDetail.localCurrency !== currency;
  const isShowLocalPoint = couponDetail.incentiveAmount?.pointCurrency !== currency;

  if (!conditions) return null;

  let excludedDatesInfo;
  if (isNotEmptyArray(conditions.excludedDates)) {
    excludedDatesInfo = conditions.excludedDates.map((item, index) => {
      if (item.type === 'RANGE') {
        return (
          <div key={`${index}-${item.type}`}>
            {labels.excludedDates.infoResolver(item.from, item.to)}
          </div>
        );
      } else if (isNotEmptyArray(item.values)) {
        return item.values.map((date, index) => (
          <React.Fragment key={date}>
            {index !== 0 ? <>,&nbsp;</> : <></>}
            {labels.excludedDates.infoResolver(null, null, date)}
          </React.Fragment>
        ));
      } else {
        return null;
      }
    });
  }

  return (
    <div data-testid="couponDetail-wrapper" className={className} {...rest}>
      {customHeaderTitle && <div className={styles.header}>{customHeaderTitle}</div>}

      <TextRow
        key="applicableAreaTitle"
        className={couponTextClassName}
        isHidden={!conditions.applicableArea?.name}
        title={labels.applicableAreaTitle.title}
        info={labels.applicableAreaTitle.infoResolver(conditions.applicableArea?.name)}
      />

      <TextRow
        key="applicableMarketTitle"
        className={couponTextClassName}
        isHidden={!isNotEmptyArray(applicableMarkets)}
        title={labels.applicableMarkets?.title}
        info={labels.applicableMarkets?.infoResolver(applicableMarkets)}
      />

      <TextRow
        key="maxUsesPerMember"
        className={couponTextClassName}
        title={labels.maxUsesPerMember.title}
        isHidden={
          !conditions.maxUse && !conditions.maxUsesPerMember && !conditions.maxUsesPerProvider
        }
        info={labels.maxUsesPerMember.infoResolver(
          conditions.maxUsesPerMember,
          conditions.maxUse,
          conditions.maxUsesPerProvider,
        )}
      />

      <TextRow
        key="travelPeriod"
        className={couponTextClassName}
        isHidden={!conditions.travelPeriodStart && !conditions.travelPeriodEnd}
        title={labels.travelPeriod.title}
        info={labels.travelPeriod.infoResolver(
          conditions.travelPeriodStart,
          conditions.travelPeriodEnd,
        )}
      />

      <TextRow
        key="stayLength"
        className={couponTextClassName}
        isHidden={!conditions.stayLengthFrom && !conditions.stayLengthTo}
        title={labels.stayLength.title}
        info={labels.stayLength.infoResolver(conditions.stayLengthFrom, conditions.stayLengthTo)}
      />

      <TextRow
        key="excludedDates"
        className={couponTextClassName}
        isHidden={!excludedDatesInfo}
        title={labels.excludedDates.title}
        info={excludedDatesInfo}
      />

      <TextRow
        key="target_weekdays"
        className={couponTextClassName}
        isHidden={!conditions.daysOfTheWeek || !isNotEmptyArray(conditions.daysOfTheWeek)}
        title={labels.target_weekdays.title}
        info={labels.target_weekdays.infoResolver(conditions.daysOfTheWeek)}
      />

      <TextRow
        key="noOfGuests"
        className={couponTextClassName}
        isHidden={!conditions.noOfGuestsFrom && !conditions.noOfGuestsTo}
        title={labels.noOfGuests.title}
        info={labels.noOfGuests.infoResolver(conditions.noOfGuestsFrom, conditions.noOfGuestsTo)}
      />

      <TextRow
        key="noOfAdults"
        className={couponTextClassName}
        isHidden={!conditions.noOfAdultsFrom && !conditions.noOfAdultsTo}
        title={labels.noOfAdults.title}
        info={labels.noOfAdults.infoResolver(conditions.noOfAdultsFrom, conditions.noOfAdultsTo)}
      />

      <TextRow
        key="noOfChildren"
        className={couponTextClassName}
        isHidden={!conditions.noOfChildrenFrom && !conditions.noOfChildrenTo}
        title={labels.noOfChildren.title}
        info={labels.noOfChildren.infoResolver(
          conditions.noOfChildrenFrom,
          conditions.noOfChildrenTo,
        )}
      />

      <TextRow
        key="reservationAmount"
        className={couponTextClassName}
        isHidden={!conditions.reservationAmountFrom && !conditions.reservationAmountTo}
        title={labels.reservationAmount.title}
        info={labels.reservationAmount.infoResolver(
          conditions.reservationAmountFrom,
          conditions.reservationAmountTo,
        )}
      />

      <TextRow
        key="incentiveDiscountAmount"
        className={couponTextClassName}
        isHidden={
          !incentiveAmount.limit ||
          (isShowLocalAmount && !incentiveAmount.localLimit) ||
          incentiveAmount.incentiveCalcMethod !== INCENTIVE_CALCULATION_METHOD.PERCENT ||
          incentiveAmount.incentiveMethod !== INCENTIVE_METHOD.DISCOUNT
        }
        title={labels.maxDiscount.title}
        info={labels.maxDiscount.infoResolver(
          incentiveAmount.limit,
          isShowLocalAmount,
          incentiveAmount.localLimit,
        )}
      />

      <TextRow
        key="incentivePointAmount"
        className={couponTextClassName}
        isHidden={
          !incentiveAmount.limit ||
          (isShowLocalPoint && !incentiveAmount.localLimit) ||
          incentiveAmount.incentiveCalcMethod !== INCENTIVE_CALCULATION_METHOD.PERCENT ||
          incentiveAmount.incentiveMethod !== INCENTIVE_METHOD.POINT
        }
        title={labels.maxPoint?.title}
        info={labels.maxPoint?.infoResolver(
          incentiveAmount.limit,
          isShowLocalPoint,
          incentiveAmount.localLimit,
        )}
      />

      <TextRow
        key="payment"
        className={couponTextClassName}
        isHidden={!isNotEmptyArray(conditions.paymentMethods)}
        title={labels.payment.title}
        info={labels.payment.infoResolver(conditions.paymentMethods)}
      />

      <TextRow
        key="userCondition"
        className={couponTextClassName}
        isHidden={
          !conditions.rakutenMemberRequired &&
          !conditions.standardNewUserRequired &&
          !conditions.providerNewUserRequired
        }
        title={labels.userCondition.title}
        info={labels.userCondition.infoResolver(
          conditions.rakutenMemberRequired,
          conditions.standardNewUserRequired,
          conditions.providerNewUserRequired,
        )}
      />

      <TextRow
        key="budget"
        className={couponTextClassName}
        isHidden={!conditions.rakutenTotalBudget && !conditions.providerTotalBudget}
        title={labels.budget.title}
        info={labels.budget.infoResolver(
          conditions.rakutenTotalBudget,
          conditions.providerTotalBudget,
        )}
      />
    </div>
  );
}

const defaultResolver = () => null;

CouponDetail.defaultProps = {
  className: '',
  labels: {
    applicableAreaTitle: {
      title: 'Area applicable',
      infoResolver: defaultResolver,
    },
    maxUsesPerMember: {
      title: 'Maximum usage',
      infoResolver: defaultResolver,
    },
    travelPeriod: {
      title: 'Travel period',
      infoResolver: defaultResolver,
    },
    excludedDates: {
      title: 'Excluded dates',
      infoResolver: defaultResolver,
    },
    target_weekdays: {
      title: 'Target days of the week',
      infoResolver: defaultResolver,
    },
    noOfGuests: {
      title: 'Total guests',
      infoResolver: defaultResolver,
    },
    noOfAdults: {
      title: 'Total no. of adults',
      infoResolver: defaultResolver,
    },
    noOfChildren: {
      title: 'Total no. of children',
      infoResolver: defaultResolver,
    },
    stayLength: {
      title: 'Reservation length',
      infoResolver: defaultResolver,
    },
    reservationAmount: {
      title: 'Reservation amount',
      infoResolver: defaultResolver,
    },
    maxDiscount: {
      title: 'Maximum discount',
      infoResolver: defaultResolver,
    },
    payment: {
      title: 'Payment',
      infoResolver: defaultResolver,
    },
    userCondition: {
      title: 'User conditions',
      infoResolver: defaultResolver,
    },
    budget: {
      title: 'Budget Disclaimer',
      infoResolver: defaultResolver,
    },
    maxPoint: {
      title: 'Max Point',
      infoResolver: defaultResolver,
    },
    applicableMarkets: {
      title: 'Applicable Markets',
      infoResolver: defaultResolver,
    },
  },
};

export default React.memo(CouponDetail);
