import { isNotEmptyArray } from '@travel/utils';
import React, { ReactNode } from 'react';

export type Option = {
  text: ReactNode;
  value: string | number;
  icon?: ReactNode;
  isDisabled?: boolean;
};

export default function Options(props: {
  options: Array<Option>;
  selectedValue?: string | number;
}) {
  if (!isNotEmptyArray(props.options)) return null;
  return (
    <>
      {props.options.map(option => {
        return (
          <option
            data-testid={`option-${option.value}`}
            key={option.value}
            value={option.value}
            selected={option.value === props.selectedValue}
            disabled={option.isDisabled}
          >
            {option.text}
          </option>
        );
      })}
    </>
  );
}
